import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";

export const getLatestCompanyKeyFigure = function (company) {
  if (!company?.keyFigures) {
    return null;
  }

  const sorted = [...company?.keyFigures].sort((a, b) => a.year - b.year);

  let out = {};

  const latest = sorted[sorted.length - 1];

  Object.keys(latest).forEach((key) => {
    out[key] = { value: latest[key] };
  });

  if (sorted.length > 1) {
    const previous = sorted[sorted.length - 2];
    Object.keys(previous).forEach((key) => {
      const percentageDiff = ((latest[key] - previous[key]) / previous[key]) * 100;
      out[key].development = percentageDiff;
    });
  }

  return out;
};

const getCompanyProperies = function (companies) {
  return companies?.flatMap((company) => company.properties).filter((property) => property);
};

export const getCompanyMainProperties = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_MAIN_PROPERTY) || [];
};

export const getCompanyCondos = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_CONDO) || [];
};

export const getCompanyBofp = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT) || [];
};

export const getCompanyUnknownProperties = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_UNKNOWN) || [];
};
